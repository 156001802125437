const affixBanner = () => {
  const IDEAL_TOP = 210;
  const EXTREMITIES_OFFSET = 50;

  const banner = document.querySelector('.ft_async_tag');
  if (!banner) return;

  const header = document.querySelector('.header-container');
  const footer = document.querySelector('.footer');

  let topThreshold = EXTREMITIES_OFFSET;
  if (header) topThreshold += header.getBoundingClientRect().height;

  let bottomThreshold = EXTREMITIES_OFFSET;
  if (footer) bottomThreshold += footer.getBoundingClientRect().height;

  const scrollHandler = () => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const scrollBottom = document.body.scrollHeight - windowHeight - scrollTop;
    const bannerHeight = banner.getBoundingClientRect().height;
    const idealBottom = windowHeight - IDEAL_TOP - bannerHeight;

    if (scrollTop + IDEAL_TOP < topThreshold) {
      const top = topThreshold - scrollTop;
      banner.style.setProperty('top', `${top}px`);
    } else if (scrollBottom + idealBottom < bottomThreshold) {
      const top = windowHeight - bannerHeight - bottomThreshold + scrollBottom;
      banner.style.setProperty('top', `${top}px`);
    } else {
      // will be set through the css class
      banner.style.removeProperty('top');
    }
  };

  window.document.addEventListener('scroll', scrollHandler);
};

export default () => {
  window.addEventListener('load', affixBanner);
};
