import { registerLogger } from '@vue-storefront/core'

const loggerPlugin = (ctx) => {
  const { verbosity, customLogger, ...args } = {"verbosity":"debug","customLogger":(verbosity) => {
  const logData = (
  { message, args },
  logType) =>
  {
    const time = new Date().toISOString();
    const record = {
      time,
      type: 'VSF',
      status: logType,
      message: [message, ...args].join(' ') };

    const stringifiedData = JSON.stringify(record);
    console.log(stringifiedData);
  };

  return {
    debug: (message, ...args) => logData({ message, args }, 'debug'),
    info: (message, ...args) => logData({ message, args }, 'info'),
    warn: (message, ...args) => logData({ message, args }, 'warn'),
    error: (message, ...args) => logData({ message, args }, 'error') };
}};
  registerLogger(customLogger || args, verbosity || 'error')
};

export default loggerPlugin;
