































































import { defineComponent, computed, useContext } from '@nuxtjs/composition-api';
import useUrlFormatter from '~/hooks/useUrlFormatter';
import useWindowResize from '~/hooks/useResizeWindow';
// @ts-ignore
import errorMedia404 from '@/assets/images/ke_404_foutmelding_illustratie.svg';

export default defineComponent({
  name: 'ErrorPage',
  layout: 'default',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const formatUrl = useUrlFormatter();
    const { i18n } = useContext();
    const { isDesktop } = useWindowResize();

    const imageHeight = isDesktop.value ? 298 : 250;
    const imageWidth = isDesktop.value ? 455 : 360;

    const statusCode = computed(() => {
      return props.error.statusCode || 404;
    });

    return {
      formatUrl,
      statusCode,
      errorMedia404,
      imageHeight,
      imageWidth,
      i18n
    };
  }
});
