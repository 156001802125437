var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sf-button',_vm._g({class:{
    'small-button': _vm.small,
    'sf-button--primary-black': _vm.variant === 'primary-black',
    'sf-button--outline': _vm.variant === 'secondary',
    'sf-button--text': _vm.variant === 'tertiary',
    'sf-button--text no-underline': _vm.variant === 'tertiary-no-underline',
    'icon-only': _vm.iconOnly,
    loading: _vm.loading,
    'full-width': _vm.fullWidth
  },attrs:{"disabled":_vm.disabled}},_vm.$listeners),[(_vm.loading)?[_vm._v("\n    "+_vm._s(_vm.loadingMessage)+"\n  ")]:[_vm._t("icon"),_vm._v(" "),_vm._t("default")]],2)}
var staticRenderFns = []

export { render, staticRenderFns }