import { integrationPlugin } from '@vue-storefront/core';
import { createNovuloState, setStoreState } from '@vue-storefront/novulo-api';

const moduleOptions = JSON.parse('{}');

export default integrationPlugin(
  ({ app, req, res, integration, i18n, $config }) => {
    integration.configure('novulo', {
      ...moduleOptions
      // other options
    });

    if (process.server) {
      app.$vsf.$novulo.client.defaults.baseURL = 'http://127.0.0.1:3000/api/';
    } else {
      app.$vsf.$novulo.client.defaults.baseURL = '/api/';
    }

    app.$vsf.$novulo.config.state = createNovuloState(req, res);

    const state = app.$vsf.$novulo.config.state;
    app.$vsf.$novulo.client.interceptors.request.use(function (config) {
      config.headers[state.getCustomerHeaderName()] =
        state.getCustomerToken() ?? '';
      config.headers[state.getCartHeaderName()] = state.getCartId() ?? '';
      if (state.getWt() && state.getLanguage()) {
        config.headers[state.getWtHeaderName()] = state.getWt() ?? '';
        config.headers[state.getLanguageHeaderName()] =
          state.getLanguage() ?? '';
      } else {
        throw Error(
          `WT or Language not defined for request to ${config.url} from url ${
            req?.url ?? window?.location ?? 'unknown'
          }`
        );
      }
      // TODO: throw error if sales channel is not defined when it becomes mandatory
      if (state.getSalesChannel()) {
        config.headers[state.getSalesChannelHeaderName()] =
          state.getSalesChannel() ?? '';
      }

      return config;
    }, undefined);

    setStoreState({ app, req, res, i18n, $config });
  }
);
