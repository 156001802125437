const middleware = {}

middleware['checkout'] = require('../_theme/middleware/checkout.js')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['gtm-init-params'] = require('../_theme/middleware/gtm-init-params.ts')
middleware['gtm-init-params'] = middleware['gtm-init-params'].default || middleware['gtm-init-params']

middleware['is-authenticated'] = require('../_theme/middleware/is-authenticated.js')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['pagerouter'] = require('../_theme/middleware/pagerouter.ts')
middleware['pagerouter'] = middleware['pagerouter'].default || middleware['pagerouter']

middleware['storehandler'] = require('../_theme/middleware/storehandler.js')
middleware['storehandler'] = middleware['storehandler'].default || middleware['storehandler']

export default middleware
