import Vue from 'vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { extend } from 'vee-validate';
import { required, email, min, max, numeric } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'field_required_message'
});

extend('email', {
  ...email,
  message: 'invalid_email_message'
});

extend('min', {
  ...min,
  message: 'min_length_message'
});

extend('max', {
  ...max,
  message: 'max_length_message'
});

extend('phone', {
  message: 'invalid_phone_number_message',
  validate: (value) => {
    const regex = /^\+?[0-9]+$/;
    return regex.test(value);
  }
});

const countryPostalCodesValidations = {
  DK: /^\d{4}|[ ]?[A-Za-z]/,
  SE: /^\d{3}\s*\d{2}$|[a-zA-Z]{2}[-]\d{3}\s*\d{2}$|[a-zA-Z]{2}[ ]\d{3}\s*\d{2}$/,
  PL: /^\d{2}[- ]{0,1}\d{3}$|[0-9]{4}/,
  NL: /^[1-9][0-9]{3}\s?[a-zA-Z]{2}/,
  GB: /^([Gg][Ii][Rr]\s?0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/,
  IE: /^([AC-FHKNPRTV-Y]{1}[0-9]{2}|D6W)[ ]?[0-9AC-FHKNPRTV-Y]{4}$/,
  SK: /^((\d{5}\s*)|(\d{3}\s*\d{2}))$/,
  IT: /[I]?[ ]|[I]?[-]|[0-9]{5}/,
  PT: /[0-9]{7}|[0-9]{4}|[I]?[ ]|[I]?[-]|[0-9]{3}/,
  DE: /^[0-9]{5}$/,
  FI: /^[0-9]{5}$/,
  EE: /^[0-9]{5}$/,
  ES: /^[0-9]{5}$/,
  FR: /^[0-9]{5}$/,
  AT: /^[0-9]{4}$/,
  NO: /^[0-9]{4}$/,
  AF: /^[0-9]{4}$/,
  CH: /^[0-9]{4}$/,
  SI: /^[0-9]{4}$/,
  LU: /^[0-9]{4}$/,
  BE: /^[0-9]{4}$/
};

extend('postcode', {
  message: 'invalid_post_code_message',
  validate: (value, args) => {
    return value.match(countryPostalCodesValidations[args[0]]);
  }
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
