export default (context) => {
  if (!context.$config.domainConfig) {
    // no domain config set (probably serving media)
    return;
  }

  const i18nSetLocale = context.app.i18n.setLocale;
  const override = async (newLocale) => {
    // const strategy =
    //   context.app.i18n.defaultLocale == newLocale
    //     ? 'prefix_and_default'
    //     : 'prefix_and_default';
    context.app.keI18n.setStrategy('strategy');
    return i18nSetLocale(newLocale);
  };
  context.app.i18n.setLocale = override;
};
