/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { IncomingMessage as Request, ServerResponse as Response } from 'http';
import  CookieUniversal  from 'cookie-universal';
import { CookieSerializeOptions } from 'cookie'

const baseSetCookieOptions = {
    sameSite: 'lax',
    path: '/',
};

declare var window: any | undefined;
const isBrowser = () => typeof window !== 'undefined';

export default (req? : Request, res? : Response) => {
    const cookie = isBrowser() ? CookieUniversal() : CookieUniversal(req, res);
    const set = (id : string, data : string, options = {}) => {
        cookie.set(id, data, {
            ...baseSetCookieOptions,
            ...options,
        } as CookieSerializeOptions);
    };

    const remove = (id : string, options = {}) => {
        cookie.remove(id, {
            ...baseSetCookieOptions,
            ...options,
        } as CookieSerializeOptions );
    };

    const get = (id : string, options = {}) => cookie.get(id, {
        ...options,
    });

    return {
        set,
        remove,
        get,
    };
};
