import { options } from '/.nuxt/nuxt-i18n/options';

export default function (context) {
  const domainConfig = context.$config.domainConfig;
  if (!domainConfig) {
    // no domain config set (probably serving media)
    return;
  }

  const defaultLanguage = Object.keys(domainConfig.lang)[0];

  const keI18n = {
    getDefaultLocale() {
      return options.defaultLocale;
    },
    setDefaultLocale: (defaultLanguage) => {
      options.defaultLocale = defaultLanguage;
    },
    getStrategy: () => {
      return options.strategy;
    },
    setStrategy: (strategy) => {
      options.strategy = strategy;
    }
  };

  const localeCodes = options.localeCodes;
  const pattern = new RegExp(`^/(${localeCodes.join('|')})(?:/|$)`);

  const getLocaleFromPath = (path) => {
    if (path) {
      return (pattern.exec(path) || [])[1];
    }
  };

  context.app.router.beforeEach((to, from, next) => {
    if (to.name && (to.name.includes('pdp') || to.name.includes('PLP'))) {
      next();
    }
    const pathLocale = getLocaleFromPath(to.path);
    if (pathLocale == defaultLanguage) {
      const toPath = to.path.slice(pathLocale.length + 1);
      if (!process.client && from.name == null) {
        context.redirect(toPath);
      } else {
        keI18n.setStrategy('no_prefix');
        next(toPath);
      }
    }
    next();
  });

  context.app.keI18n = keI18n;
  keI18n.setDefaultLocale(defaultLanguage);
  try {
    const pathLocale = getLocaleFromPath(context.route.path);
    if (!pathLocale || pathLocale == defaultLanguage) {
      //prevent switching of the locale when i18n is initialized
      keI18n.setStrategy('no_prefix');
    } else {
      keI18n.setStrategy('prefix');
    }
  } catch (e) {
    console.error(e);
  }
}
