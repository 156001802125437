

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"numberFormats":{"nl":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"},"decimal":{"style":"decimal","minimumFractionDigits":2,"maximumFractionDigits":2},"percent":{"style":"percent","useGrouping":false}}}},
  vueI18nLoader: false,
  locales: [{"code":"nl","alias":"nl","label":"Dutch","file":"nl.js","iso":"nl"},{"code":"fr","alias":"fr","label":"French","file":"fr.js","iso":"fr"},{"code":"en","alias":"en","label":"English","file":"en.js","iso":"en"},{"code":"de","alias":"de","label":"German","file":"de.js","iso":"de"},{"code":"it","alias":"it","label":"Italy","file":"it.js","iso":"it"},{"code":"es","alias":"es","label":"Spain","file":"es.js","iso":"es"}],
  get defaultLocale() {
      return this.__defaultLocale;
  },
  set defaultLocale(l) {
    this.__defaultLocale = l;
  },
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  get strategy() {
      return this.__strategy;
  },
  set strategy(s) {
    this.__strategy = s;
  },
  lazy: true,
  langDir: "/var/www/packages/theme/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: (context) => {
      // get the hostname from http request headers on the server side and save it to nuxtState
      if (process.server) {
        const { req, beforeNuxtRender } = context;
        beforeNuxtRender(({ nuxtState }) => {
          nuxtState.host = req.headers.host;
        });
      }
      return (
        'https://' + (
        process.server ? context.req.headers.host : context.nuxtState.host));
    },
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  autoRedirectByLocale: false,
  currency: "EUR",
  country: "NL",
  countries: [{"name":"NL","label":"The Netherlands"},{"name":"DE","label":"Germany"},{"name":"BE","label":"Belgium"}],
  currencies: [{"name":"EUR","label":"Euro"}],
  seo: true,
  normalizedLocales: [{"code":"nl","alias":"nl","label":"Dutch","file":"nl.js","iso":"nl"},{"code":"fr","alias":"fr","label":"French","file":"fr.js","iso":"fr"},{"code":"en","alias":"en","label":"English","file":"en.js","iso":"en"},{"code":"de","alias":"de","label":"German","file":"de.js","iso":"de"},{"code":"it","alias":"it","label":"Italy","file":"it.js","iso":"it"},{"code":"es","alias":"es","label":"Spain","file":"es.js","iso":"es"}],
  localeCodes: ["nl","fr","en","de","it","es"],
  additionalMessages: [],
}

export const localeMessages = {
  'nl.js': () => import('../../lang/nl.js' /* webpackChunkName: "lang-nl.js" */),
  'fr.js': () => import('../../lang/fr.js' /* webpackChunkName: "lang-fr.js" */),
  'en.js': () => import('../../lang/en.js' /* webpackChunkName: "lang-en.js" */),
  'de.js': () => import('../../lang/de.js' /* webpackChunkName: "lang-de.js" */),
  'it.js': () => import('../../lang/it.js' /* webpackChunkName: "lang-it.js" */),
  'es.js': () => import('../../lang/es.js' /* webpackChunkName: "lang-es.js" */),
}
