export const AtomsAccordion = () => import('../../components/atoms/Accordion.vue' /* webpackChunkName: "components/atoms-accordion" */).then(c => wrapFunctional(c.default || c))
export const AtomsAddToCartButton = () => import('../../components/atoms/AddToCartButton.vue' /* webpackChunkName: "components/atoms-add-to-cart-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsBackButton = () => import('../../components/atoms/BackButton.vue' /* webpackChunkName: "components/atoms-back-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsBreadcrumbs = () => import('../../components/atoms/Breadcrumbs.vue' /* webpackChunkName: "components/atoms-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const AtomsBusinessLogos = () => import('../../components/atoms/BusinessLogos.vue' /* webpackChunkName: "components/atoms-business-logos" */).then(c => wrapFunctional(c.default || c))
export const AtomsCardMegaMenuLevelOne = () => import('../../components/atoms/CardMegaMenuLevelOne.vue' /* webpackChunkName: "components/atoms-card-mega-menu-level-one" */).then(c => wrapFunctional(c.default || c))
export const AtomsCardMegaMenuLevelOneMobile = () => import('../../components/atoms/CardMegaMenuLevelOneMobile.vue' /* webpackChunkName: "components/atoms-card-mega-menu-level-one-mobile" */).then(c => wrapFunctional(c.default || c))
export const AtomsCartIcon = () => import('../../components/atoms/CartIcon.vue' /* webpackChunkName: "components/atoms-cart-icon" */).then(c => wrapFunctional(c.default || c))
export const AtomsChip = () => import('../../components/atoms/Chip.vue' /* webpackChunkName: "components/atoms-chip" */).then(c => wrapFunctional(c.default || c))
export const AtomsCloseButton = () => import('../../components/atoms/CloseButton.vue' /* webpackChunkName: "components/atoms-close-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsCounter = () => import('../../components/atoms/Counter.vue' /* webpackChunkName: "components/atoms-counter" */).then(c => wrapFunctional(c.default || c))
export const AtomsDivider = () => import('../../components/atoms/Divider.vue' /* webpackChunkName: "components/atoms-divider" */).then(c => wrapFunctional(c.default || c))
export const AtomsErrorBoundary = () => import('../../components/atoms/ErrorBoundary.vue' /* webpackChunkName: "components/atoms-error-boundary" */).then(c => wrapFunctional(c.default || c))
export const AtomsExpandableList = () => import('../../components/atoms/ExpandableList.vue' /* webpackChunkName: "components/atoms-expandable-list" */).then(c => wrapFunctional(c.default || c))
export const AtomsFavoriteButton = () => import('../../components/atoms/FavoriteButton.vue' /* webpackChunkName: "components/atoms-favorite-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsFeatureProgressBar = () => import('../../components/atoms/FeatureProgressBar.vue' /* webpackChunkName: "components/atoms-feature-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const AtomsFeedbackButton = () => import('../../components/atoms/FeedbackButton.vue' /* webpackChunkName: "components/atoms-feedback-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsImageOrFallback = () => import('../../components/atoms/ImageOrFallback.vue' /* webpackChunkName: "components/atoms-image-or-fallback" */).then(c => wrapFunctional(c.default || c))
export const AtomsInPageNavigation = () => import('../../components/atoms/InPageNavigation.vue' /* webpackChunkName: "components/atoms-in-page-navigation" */).then(c => wrapFunctional(c.default || c))
export const AtomsInlineNotification = () => import('../../components/atoms/InlineNotification.vue' /* webpackChunkName: "components/atoms-inline-notification" */).then(c => wrapFunctional(c.default || c))
export const AtomsInputField = () => import('../../components/atoms/InputField.vue' /* webpackChunkName: "components/atoms-input-field" */).then(c => wrapFunctional(c.default || c))
export const AtomsKEAddressFinder = () => import('../../components/atoms/KEAddressFinder.vue' /* webpackChunkName: "components/atoms-k-e-address-finder" */).then(c => wrapFunctional(c.default || c))
export const AtomsKEButton = () => import('../../components/atoms/KEButton.vue' /* webpackChunkName: "components/atoms-k-e-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsKEButtonFeedback = () => import('../../components/atoms/KEButtonFeedback.vue' /* webpackChunkName: "components/atoms-k-e-button-feedback" */).then(c => wrapFunctional(c.default || c))
export const AtomsKECountries = () => import('../../components/atoms/KECountries.vue' /* webpackChunkName: "components/atoms-k-e-countries" */).then(c => wrapFunctional(c.default || c))
export const AtomsKEDrawer = () => import('../../components/atoms/KEDrawer.vue' /* webpackChunkName: "components/atoms-k-e-drawer" */).then(c => wrapFunctional(c.default || c))
export const AtomsKELabel = () => import('../../components/atoms/KELabel.vue' /* webpackChunkName: "components/atoms-k-e-label" */).then(c => wrapFunctional(c.default || c))
export const AtomsKESfInput = () => import('../../components/atoms/KESfInput.vue' /* webpackChunkName: "components/atoms-k-e-sf-input" */).then(c => wrapFunctional(c.default || c))
export const AtomsKETable = () => import('../../components/atoms/KETable.vue' /* webpackChunkName: "components/atoms-k-e-table" */).then(c => wrapFunctional(c.default || c))
export const AtomsKETextArea = () => import('../../components/atoms/KETextArea.vue' /* webpackChunkName: "components/atoms-k-e-text-area" */).then(c => wrapFunctional(c.default || c))
export const AtomsKETextSection = () => import('../../components/atoms/KETextSection.vue' /* webpackChunkName: "components/atoms-k-e-text-section" */).then(c => wrapFunctional(c.default || c))
export const AtomsLanguageSelector = () => import('../../components/atoms/LanguageSelector.vue' /* webpackChunkName: "components/atoms-language-selector" */).then(c => wrapFunctional(c.default || c))
export const AtomsList = () => import('../../components/atoms/List.vue' /* webpackChunkName: "components/atoms-list" */).then(c => wrapFunctional(c.default || c))
export const AtomsMarkdownRender = () => import('../../components/atoms/MarkdownRender.vue' /* webpackChunkName: "components/atoms-markdown-render" */).then(c => wrapFunctional(c.default || c))
export const AtomsMegaMenuCard = () => import('../../components/atoms/MegaMenuCard.vue' /* webpackChunkName: "components/atoms-mega-menu-card" */).then(c => wrapFunctional(c.default || c))
export const AtomsMegaMenuLevelOne = () => import('../../components/atoms/MegaMenuLevelOne.vue' /* webpackChunkName: "components/atoms-mega-menu-level-one" */).then(c => wrapFunctional(c.default || c))
export const AtomsMegaMenuLevelOneMobile = () => import('../../components/atoms/MegaMenuLevelOneMobile.vue' /* webpackChunkName: "components/atoms-mega-menu-level-one-mobile" */).then(c => wrapFunctional(c.default || c))
export const AtomsMegaMenuLevelTwo = () => import('../../components/atoms/MegaMenuLevelTwo.vue' /* webpackChunkName: "components/atoms-mega-menu-level-two" */).then(c => wrapFunctional(c.default || c))
export const AtomsMegaMenuLevelTwoMobile = () => import('../../components/atoms/MegaMenuLevelTwoMobile.vue' /* webpackChunkName: "components/atoms-mega-menu-level-two-mobile" */).then(c => wrapFunctional(c.default || c))
export const AtomsModal = () => import('../../components/atoms/Modal.vue' /* webpackChunkName: "components/atoms-modal" */).then(c => wrapFunctional(c.default || c))
export const AtomsNavigationButton = () => import('../../components/atoms/NavigationButton.vue' /* webpackChunkName: "components/atoms-navigation-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsPanel = () => import('../../components/atoms/Panel.vue' /* webpackChunkName: "components/atoms-panel" */).then(c => wrapFunctional(c.default || c))
export const AtomsPrice = () => import('../../components/atoms/Price.vue' /* webpackChunkName: "components/atoms-price" */).then(c => wrapFunctional(c.default || c))
export const AtomsProductDescription = () => import('../../components/atoms/ProductDescription.vue' /* webpackChunkName: "components/atoms-product-description" */).then(c => wrapFunctional(c.default || c))
export const AtomsProductDropdown = () => import('../../components/atoms/ProductDropdown.vue' /* webpackChunkName: "components/atoms-product-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AtomsProgressBar = () => import('../../components/atoms/ProgressBar.vue' /* webpackChunkName: "components/atoms-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const AtomsPromo = () => import('../../components/atoms/Promo.vue' /* webpackChunkName: "components/atoms-promo" */).then(c => wrapFunctional(c.default || c))
export const AtomsPromotionBubble = () => import('../../components/atoms/PromotionBubble.vue' /* webpackChunkName: "components/atoms-promotion-bubble" */).then(c => wrapFunctional(c.default || c))
export const AtomsQuantitySelector = () => import('../../components/atoms/QuantitySelector.vue' /* webpackChunkName: "components/atoms-quantity-selector" */).then(c => wrapFunctional(c.default || c))
export const AtomsRangeSelector = () => import('../../components/atoms/RangeSelector.vue' /* webpackChunkName: "components/atoms-range-selector" */).then(c => wrapFunctional(c.default || c))
export const AtomsRatingProgressBar = () => import('../../components/atoms/RatingProgressBar.vue' /* webpackChunkName: "components/atoms-rating-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const AtomsRatingStarsBar = () => import('../../components/atoms/RatingStarsBar.vue' /* webpackChunkName: "components/atoms-rating-stars-bar" */).then(c => wrapFunctional(c.default || c))
export const AtomsReview = () => import('../../components/atoms/Review.vue' /* webpackChunkName: "components/atoms-review" */).then(c => wrapFunctional(c.default || c))
export const AtomsReviewsBar = () => import('../../components/atoms/ReviewsBar.vue' /* webpackChunkName: "components/atoms-reviews-bar" */).then(c => wrapFunctional(c.default || c))
export const AtomsSearchBar = () => import('../../components/atoms/SearchBar.vue' /* webpackChunkName: "components/atoms-search-bar" */).then(c => wrapFunctional(c.default || c))
export const AtomsSearchSuggestion = () => import('../../components/atoms/SearchSuggestion.vue' /* webpackChunkName: "components/atoms-search-suggestion" */).then(c => wrapFunctional(c.default || c))
export const AtomsSelect = () => import('../../components/atoms/Select.vue' /* webpackChunkName: "components/atoms-select" */).then(c => wrapFunctional(c.default || c))
export const AtomsSelectableRatingStarsBar = () => import('../../components/atoms/SelectableRatingStarsBar.vue' /* webpackChunkName: "components/atoms-selectable-rating-stars-bar" */).then(c => wrapFunctional(c.default || c))
export const AtomsShowMoreText = () => import('../../components/atoms/ShowMoreText.vue' /* webpackChunkName: "components/atoms-show-more-text" */).then(c => wrapFunctional(c.default || c))
export const AtomsSkeletonNavigationButton = () => import('../../components/atoms/SkeletonNavigationButton.vue' /* webpackChunkName: "components/atoms-skeleton-navigation-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsSocialContactButton = () => import('../../components/atoms/SocialContactButton.vue' /* webpackChunkName: "components/atoms-social-contact-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsSpotlightBar = () => import('../../components/atoms/SpotlightBar.vue' /* webpackChunkName: "components/atoms-spotlight-bar" */).then(c => wrapFunctional(c.default || c))
export const AtomsStepper = () => import('../../components/atoms/Stepper.vue' /* webpackChunkName: "components/atoms-stepper" */).then(c => wrapFunctional(c.default || c))
export const AtomsTab = () => import('../../components/atoms/Tab.vue' /* webpackChunkName: "components/atoms-tab" */).then(c => wrapFunctional(c.default || c))
export const AtomsTextLink = () => import('../../components/atoms/TextLink.vue' /* webpackChunkName: "components/atoms-text-link" */).then(c => wrapFunctional(c.default || c))
export const AtomsThumbnail = () => import('../../components/atoms/Thumbnail.vue' /* webpackChunkName: "components/atoms-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const AtomsToggleFiltersButton = () => import('../../components/atoms/ToggleFiltersButton.vue' /* webpackChunkName: "components/atoms-toggle-filters-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsTooltip = () => import('../../components/atoms/Tooltip.vue' /* webpackChunkName: "components/atoms-tooltip" */).then(c => wrapFunctional(c.default || c))
export const AtomsVideoButton = () => import('../../components/atoms/VideoButton.vue' /* webpackChunkName: "components/atoms-video-button" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAddressForm = () => import('../../components/checkout/AddressForm.vue' /* webpackChunkName: "components/checkout-address-form" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAddressListDrawer = () => import('../../components/checkout/AddressListDrawer.vue' /* webpackChunkName: "components/checkout-address-list-drawer" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCartSummary = () => import('../../components/checkout/CartSummary.vue' /* webpackChunkName: "components/checkout-cart-summary" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCartSummaryAccordion = () => import('../../components/checkout/CartSummaryAccordion.vue' /* webpackChunkName: "components/checkout-cart-summary-accordion" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCartSummaryDiscountForm = () => import('../../components/checkout/CartSummaryDiscountForm.vue' /* webpackChunkName: "components/checkout-cart-summary-discount-form" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCarousel = () => import('../../components/checkout/CheckoutCarousel.vue' /* webpackChunkName: "components/checkout-carousel" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCarouselBlock = () => import('../../components/checkout/CheckoutCarouselBlock.vue' /* webpackChunkName: "components/checkout-carousel-block" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryAddress = () => import('../../components/checkout/CheckoutDeliveryAddress.vue' /* webpackChunkName: "components/checkout-delivery-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliverySchedule = () => import('../../components/checkout/CheckoutDeliverySchedule.vue' /* webpackChunkName: "components/checkout-delivery-schedule" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentMethods = () => import('../../components/checkout/CheckoutPaymentMethods.vue' /* webpackChunkName: "components/checkout-payment-methods" */).then(c => wrapFunctional(c.default || c))
export const CheckoutReturnEquipment = () => import('../../components/checkout/ReturnEquipment.vue' /* webpackChunkName: "components/checkout-return-equipment" */).then(c => wrapFunctional(c.default || c))
export const CheckoutTheFooter = () => import('../../components/checkout/TheFooter.vue' /* webpackChunkName: "components/checkout-the-footer" */).then(c => wrapFunctional(c.default || c))
export const CheckoutTheHeader = () => import('../../components/checkout/TheHeader.vue' /* webpackChunkName: "components/checkout-the-header" */).then(c => wrapFunctional(c.default || c))
export const PagesPDP = () => import('../../components/pages/PDP.vue' /* webpackChunkName: "components/pages-p-d-p" */).then(c => wrapFunctional(c.default || c))
export const PagesPLP = () => import('../../components/pages/PLP.vue' /* webpackChunkName: "components/pages-p-l-p" */).then(c => wrapFunctional(c.default || c))
export const PagesCart = () => import('../../components/pages/cart.vue' /* webpackChunkName: "components/pages-cart" */).then(c => wrapFunctional(c.default || c))
export const PagesCheckout = () => import('../../components/pages/checkout.vue' /* webpackChunkName: "components/pages-checkout" */).then(c => wrapFunctional(c.default || c))
export const PagesCmsPage = () => import('../../components/pages/cms-page.vue' /* webpackChunkName: "components/pages-cms-page" */).then(c => wrapFunctional(c.default || c))
export const PagesRegistration = () => import('../../components/pages/registration.vue' /* webpackChunkName: "components/pages-registration" */).then(c => wrapFunctional(c.default || c))
export const PagesStore = () => import('../../components/pages/store.vue' /* webpackChunkName: "components/pages-store" */).then(c => wrapFunctional(c.default || c))
export const PagesStores = () => import('../../components/pages/stores.vue' /* webpackChunkName: "components/pages-stores" */).then(c => wrapFunctional(c.default || c))
export const PagesThankYou = () => import('../../components/pages/thank-you.vue' /* webpackChunkName: "components/pages-thank-you" */).then(c => wrapFunctional(c.default || c))
export const MoleculesAdditionalServices = () => import('../../components/molecules/AdditionalServices.vue' /* webpackChunkName: "components/molecules-additional-services" */).then(c => wrapFunctional(c.default || c))
export const MoleculesAlternativeProduct = () => import('../../components/molecules/AlternativeProduct.vue' /* webpackChunkName: "components/molecules-alternative-product" */).then(c => wrapFunctional(c.default || c))
export const MoleculesAskAdvice = () => import('../../components/molecules/AskAdvice.vue' /* webpackChunkName: "components/molecules-ask-advice" */).then(c => wrapFunctional(c.default || c))
export const MoleculesAvailability = () => import('../../components/molecules/Availability.vue' /* webpackChunkName: "components/molecules-availability" */).then(c => wrapFunctional(c.default || c))
export const MoleculesAvailabilityDrawer = () => import('../../components/molecules/AvailabilityDrawer.vue' /* webpackChunkName: "components/molecules-availability-drawer" */).then(c => wrapFunctional(c.default || c))
export const MoleculesBuySectionDelivery = () => import('../../components/molecules/BuySectionDelivery.vue' /* webpackChunkName: "components/molecules-buy-section-delivery" */).then(c => wrapFunctional(c.default || c))
export const MoleculesBuySectionDeliveryModal = () => import('../../components/molecules/BuySectionDeliveryModal.vue' /* webpackChunkName: "components/molecules-buy-section-delivery-modal" */).then(c => wrapFunctional(c.default || c))
export const MoleculesBuySectionDeliverySubscriptionDrawer = () => import('../../components/molecules/BuySectionDeliverySubscriptionDrawer.vue' /* webpackChunkName: "components/molecules-buy-section-delivery-subscription-drawer" */).then(c => wrapFunctional(c.default || c))
export const MoleculesBuySectionUspDrawer = () => import('../../components/molecules/BuySectionUspDrawer.vue' /* webpackChunkName: "components/molecules-buy-section-usp-drawer" */).then(c => wrapFunctional(c.default || c))
export const MoleculesBuySectionUsps = () => import('../../components/molecules/BuySectionUsps.vue' /* webpackChunkName: "components/molecules-buy-section-usps" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCarePlanDrawer = () => import('../../components/molecules/CarePlanDrawer.vue' /* webpackChunkName: "components/molecules-care-plan-drawer" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCmsCustomButtonWrapper = () => import('../../components/molecules/CmsCustomButtonWrapper.vue' /* webpackChunkName: "components/molecules-cms-custom-button-wrapper" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCmsUrlWrapper = () => import('../../components/molecules/CmsUrlWrapper.vue' /* webpackChunkName: "components/molecules-cms-url-wrapper" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCombiDeals = () => import('../../components/molecules/CombiDeals.vue' /* webpackChunkName: "components/molecules-combi-deals" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCombiDealsItem = () => import('../../components/molecules/CombiDealsItem.vue' /* webpackChunkName: "components/molecules-combi-deals-item" */).then(c => wrapFunctional(c.default || c))
export const MoleculesFilterFacet = () => import('../../components/molecules/FilterFacet.vue' /* webpackChunkName: "components/molecules-filter-facet" */).then(c => wrapFunctional(c.default || c))
export const MoleculesFooterFootnoteLinks = () => import('../../components/molecules/FooterFootnoteLinks.vue' /* webpackChunkName: "components/molecules-footer-footnote-links" */).then(c => wrapFunctional(c.default || c))
export const MoleculesFooterQuickLinks = () => import('../../components/molecules/FooterQuickLinks.vue' /* webpackChunkName: "components/molecules-footer-quick-links" */).then(c => wrapFunctional(c.default || c))
export const MoleculesFooterRating = () => import('../../components/molecules/FooterRating.vue' /* webpackChunkName: "components/molecules-footer-rating" */).then(c => wrapFunctional(c.default || c))
export const MoleculesFooterSocialMedia = () => import('../../components/molecules/FooterSocialMedia.vue' /* webpackChunkName: "components/molecules-footer-social-media" */).then(c => wrapFunctional(c.default || c))
export const MoleculesFootnote = () => import('../../components/molecules/Footnote.vue' /* webpackChunkName: "components/molecules-footnote" */).then(c => wrapFunctional(c.default || c))
export const MoleculesImageGallery = () => import('../../components/molecules/ImageGallery.vue' /* webpackChunkName: "components/molecules-image-gallery" */).then(c => wrapFunctional(c.default || c))
export const MoleculesKECookie = () => import('../../components/molecules/KECookie.vue' /* webpackChunkName: "components/molecules-k-e-cookie" */).then(c => wrapFunctional(c.default || c))
export const MoleculesKESfCarousel = () => import('../../components/molecules/KESfCarousel.vue' /* webpackChunkName: "components/molecules-k-e-sf-carousel" */).then(c => wrapFunctional(c.default || c))
export const MoleculesKESfGallery = () => import('../../components/molecules/KESfGallery.vue' /* webpackChunkName: "components/molecules-k-e-sf-gallery" */).then(c => wrapFunctional(c.default || c))
export const MoleculesKESfImage = () => import('../../components/molecules/KESfImage.vue' /* webpackChunkName: "components/molecules-k-e-sf-image" */).then(c => wrapFunctional(c.default || c))
export const MoleculesKESfModal = () => import('../../components/molecules/KESfModal.vue' /* webpackChunkName: "components/molecules-k-e-sf-modal" */).then(c => wrapFunctional(c.default || c))
export const MoleculesKESfPagination = () => import('../../components/molecules/KESfPagination.vue' /* webpackChunkName: "components/molecules-k-e-sf-pagination" */).then(c => wrapFunctional(c.default || c))
export const MoleculesLinksMegaMenu = () => import('../../components/molecules/LinksMegaMenu.vue' /* webpackChunkName: "components/molecules-links-mega-menu" */).then(c => wrapFunctional(c.default || c))
export const MoleculesLoginDropdown = () => import('../../components/molecules/LoginDropdown.vue' /* webpackChunkName: "components/molecules-login-dropdown" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMegaMenu = () => import('../../components/molecules/MegaMenu.vue' /* webpackChunkName: "components/molecules-mega-menu" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMegaMenuMobile = () => import('../../components/molecules/MegaMenuMobile.vue' /* webpackChunkName: "components/molecules-mega-menu-mobile" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPDPCarePlan = () => import('../../components/molecules/PDPCarePlan.vue' /* webpackChunkName: "components/molecules-p-d-p-care-plan" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPDPIntro = () => import('../../components/molecules/PDPIntro.vue' /* webpackChunkName: "components/molecules-p-d-p-intro" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPLPIntro = () => import('../../components/molecules/PLPIntro.vue' /* webpackChunkName: "components/molecules-p-l-p-intro" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPriceFilter = () => import('../../components/molecules/PriceFilter.vue' /* webpackChunkName: "components/molecules-price-filter" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProductCard = () => import('../../components/molecules/ProductCard.vue' /* webpackChunkName: "components/molecules-product-card" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProductGrid = () => import('../../components/molecules/ProductGrid.vue' /* webpackChunkName: "components/molecules-product-grid" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPromotion = () => import('../../components/molecules/Promotion.vue' /* webpackChunkName: "components/molecules-promotion" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPromotionsDrawer = () => import('../../components/molecules/PromotionsDrawer.vue' /* webpackChunkName: "components/molecules-promotions-drawer" */).then(c => wrapFunctional(c.default || c))
export const MoleculesQuickFilters = () => import('../../components/molecules/QuickFilters.vue' /* webpackChunkName: "components/molecules-quick-filters" */).then(c => wrapFunctional(c.default || c))
export const MoleculesRelatedAccessories = () => import('../../components/molecules/RelatedAccessories.vue' /* webpackChunkName: "components/molecules-related-accessories" */).then(c => wrapFunctional(c.default || c))
export const MoleculesRelatedAccessory = () => import('../../components/molecules/RelatedAccessory.vue' /* webpackChunkName: "components/molecules-related-accessory" */).then(c => wrapFunctional(c.default || c))
export const MoleculesReviews = () => import('../../components/molecules/Reviews.vue' /* webpackChunkName: "components/molecules-reviews" */).then(c => wrapFunctional(c.default || c))
export const MoleculesSearchComponent = () => import('../../components/molecules/SearchComponent.vue' /* webpackChunkName: "components/molecules-search-component" */).then(c => wrapFunctional(c.default || c))
export const MoleculesSearchFilter = () => import('../../components/molecules/SearchFilter.vue' /* webpackChunkName: "components/molecules-search-filter" */).then(c => wrapFunctional(c.default || c))
export const MoleculesSearchFlyOut = () => import('../../components/molecules/SearchFlyOut.vue' /* webpackChunkName: "components/molecules-search-fly-out" */).then(c => wrapFunctional(c.default || c))
export const MoleculesShowMoreOverlay = () => import('../../components/molecules/ShowMoreOverlay.vue' /* webpackChunkName: "components/molecules-show-more-overlay" */).then(c => wrapFunctional(c.default || c))
export const MoleculesSignUp = () => import('../../components/molecules/SignUp.vue' /* webpackChunkName: "components/molecules-sign-up" */).then(c => wrapFunctional(c.default || c))
export const MoleculesSpecifications = () => import('../../components/molecules/Specifications.vue' /* webpackChunkName: "components/molecules-specifications" */).then(c => wrapFunctional(c.default || c))
export const MoleculesStickyBar = () => import('../../components/molecules/StickyBar.vue' /* webpackChunkName: "components/molecules-sticky-bar" */).then(c => wrapFunctional(c.default || c))
export const MoleculesStickyPDPNavigation = () => import('../../components/molecules/StickyPDPNavigation.vue' /* webpackChunkName: "components/molecules-sticky-p-d-p-navigation" */).then(c => wrapFunctional(c.default || c))
export const MoleculesTips = () => import('../../components/molecules/Tips.vue' /* webpackChunkName: "components/molecules-tips" */).then(c => wrapFunctional(c.default || c))
export const MoleculesTrustedshopsOrderDetails = () => import('../../components/molecules/TrustedshopsOrderDetails.vue' /* webpackChunkName: "components/molecules-trustedshops-order-details" */).then(c => wrapFunctional(c.default || c))
export const MoleculesWriteReviewDrawer = () => import('../../components/molecules/WriteReviewDrawer.vue' /* webpackChunkName: "components/molecules-write-review-drawer" */).then(c => wrapFunctional(c.default || c))
export const TemplatesCMSContainersMapper = () => import('../../components/templates/CMSContainersMapper.vue' /* webpackChunkName: "components/templates-c-m-s-containers-mapper" */).then(c => wrapFunctional(c.default || c))
export const TemplatesKEBuySection = () => import('../../components/templates/KEBuySection.vue' /* webpackChunkName: "components/templates-k-e-buy-section" */).then(c => wrapFunctional(c.default || c))
export const TemplatesKEFooter = () => import('../../components/templates/KEFooter.vue' /* webpackChunkName: "components/templates-k-e-footer" */).then(c => wrapFunctional(c.default || c))
export const TemplatesKEHeader = () => import('../../components/templates/KEHeader.vue' /* webpackChunkName: "components/templates-k-e-header" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsCategoryHeroBanner = () => import('../../components/cms-components/CategoryHeroBanner.vue' /* webpackChunkName: "components/cms-components-category-hero-banner" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsContentHighlight = () => import('../../components/cms-components/ContentHighlight.vue' /* webpackChunkName: "components/cms-components-content-highlight" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsEmbededVideo = () => import('../../components/cms-components/EmbededVideo.vue' /* webpackChunkName: "components/cms-components-embeded-video" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsHTMLTable = () => import('../../components/cms-components/HTMLTable.vue' /* webpackChunkName: "components/cms-components-h-t-m-l-table" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsHeroBanner = () => import('../../components/cms-components/HeroBanner.vue' /* webpackChunkName: "components/cms-components-hero-banner" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsImageComparison = () => import('../../components/cms-components/ImageComparison.vue' /* webpackChunkName: "components/cms-components-image-comparison" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsKEBigTitle = () => import('../../components/cms-components/KEBigTitle.vue' /* webpackChunkName: "components/cms-components-k-e-big-title" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsKEImage = () => import('../../components/cms-components/KEImage.vue' /* webpackChunkName: "components/cms-components-k-e-image" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsKESocials = () => import('../../components/cms-components/KESocials.vue' /* webpackChunkName: "components/cms-components-k-e-socials" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsKEText = () => import('../../components/cms-components/KEText.vue' /* webpackChunkName: "components/cms-components-k-e-text" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsKETitle = () => import('../../components/cms-components/KETitle.vue' /* webpackChunkName: "components/cms-components-k-e-title" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsMap = () => import('../../components/cms-components/Map.vue' /* webpackChunkName: "components/cms-components-map" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsNewsElements = () => import('../../components/cms-components/NewsElements.vue' /* webpackChunkName: "components/cms-components-news-elements" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsPromoBanners = () => import('../../components/cms-components/PromoBanners.vue' /* webpackChunkName: "components/cms-components-promo-banners" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsScriptContainer = () => import('../../components/cms-components/ScriptContainer.vue' /* webpackChunkName: "components/cms-components-script-container" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsSmallContentHighlight = () => import('../../components/cms-components/SmallContentHighlight.vue' /* webpackChunkName: "components/cms-components-small-content-highlight" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsUspBox = () => import('../../components/cms-components/UspBox.vue' /* webpackChunkName: "components/cms-components-usp-box" */).then(c => wrapFunctional(c.default || c))
export const WrappersProductAddToCartText = () => import('../../components/wrappers/ProductAddToCartText.vue' /* webpackChunkName: "components/wrappers-product-add-to-cart-text" */).then(c => wrapFunctional(c.default || c))
export const AtomsCheckbox = () => import('../../components/atoms/CheckboxComponent/Checkbox.vue' /* webpackChunkName: "components/atoms-checkbox" */).then(c => wrapFunctional(c.default || c))
export const AtomsCheckboxContainer = () => import('../../components/atoms/CheckboxComponent/CheckboxContainer.vue' /* webpackChunkName: "components/atoms-checkbox-container" */).then(c => wrapFunctional(c.default || c))
export const AtomsRadioButton = () => import('../../components/atoms/RadioComponent/RadioButton.vue' /* webpackChunkName: "components/atoms-radio-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsRadioContainer = () => import('../../components/atoms/RadioComponent/RadioContainer.vue' /* webpackChunkName: "components/atoms-radio-container" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAddressListDrawerCardsPickupPoint = () => import('../../components/checkout/address-list-drawer-cards/PickupPoint.vue' /* webpackChunkName: "components/checkout-address-list-drawer-cards-pickup-point" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAddressListDrawerCardsStoreLocation = () => import('../../components/checkout/address-list-drawer-cards/StoreLocation.vue' /* webpackChunkName: "components/checkout-address-list-drawer-cards-store-location" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAddressListDrawerCardsUserAddress = () => import('../../components/checkout/address-list-drawer-cards/UserAddress.vue' /* webpackChunkName: "components/checkout-address-list-drawer-cards-user-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAuthenticationDrawer = () => import('../../components/checkout/authentication/AuthenticationDrawer.vue' /* webpackChunkName: "components/checkout-authentication-drawer" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAuthenticationEmailForm = () => import('../../components/checkout/authentication/EmailForm.vue' /* webpackChunkName: "components/checkout-authentication-email-form" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAuthenticationLoginForm = () => import('../../components/checkout/authentication/LoginForm.vue' /* webpackChunkName: "components/checkout-authentication-login-form" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAuthenticationResetPasswordForm = () => import('../../components/checkout/authentication/ResetPasswordForm.vue' /* webpackChunkName: "components/checkout-authentication-reset-password-form" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryAddressCardsPickupPoint = () => import('../../components/checkout/delivery-address-cards/PickupPoint.vue' /* webpackChunkName: "components/checkout-delivery-address-cards-pickup-point" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryAddressCardsStoreLocation = () => import('../../components/checkout/delivery-address-cards/StoreLocation.vue' /* webpackChunkName: "components/checkout-delivery-address-cards-store-location" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryAddressCardsUserAddress = () => import('../../components/checkout/delivery-address-cards/UserAddress.vue' /* webpackChunkName: "components/checkout-delivery-address-cards-user-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutOrderLines = () => import('../../components/checkout/order-lines/OrderLines.vue' /* webpackChunkName: "components/checkout-order-lines" */).then(c => wrapFunctional(c.default || c))
export const CheckoutOrderLinesItem = () => import('../../components/checkout/order-lines/OrderLinesItem.vue' /* webpackChunkName: "components/checkout-order-lines-item" */).then(c => wrapFunctional(c.default || c))
export const CheckoutOrderLinesItemCareplan = () => import('../../components/checkout/order-lines/OrderLinesItemCareplan.vue' /* webpackChunkName: "components/checkout-order-lines-item-careplan" */).then(c => wrapFunctional(c.default || c))
export const CheckoutRegistrationCompanySubsection = () => import('../../components/checkout/registration/CompanySubsection.vue' /* webpackChunkName: "components/checkout-registration-company-subsection" */).then(c => wrapFunctional(c.default || c))
export const CheckoutRegistrationCredentials = () => import('../../components/checkout/registration/Credentials.vue' /* webpackChunkName: "components/checkout-registration-credentials" */).then(c => wrapFunctional(c.default || c))
export const IconsAmericanExpressIcon29px = () => import('../../components/icons/american-express/AmericanExpressIcon29px.vue' /* webpackChunkName: "components/icons-american-express-icon29px" */).then(c => wrapFunctional(c.default || c))
export const IconsAmericanExpressIconBW29px = () => import('../../components/icons/american-express/AmericanExpressIconBW29px.vue' /* webpackChunkName: "components/icons-american-express-icon-b-w29px" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowleftArrowLeft24px = () => import('../../components/icons/arrowleft/ArrowLeft24px.vue' /* webpackChunkName: "components/icons-arrowleft-arrow-left24px" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowrightArrowRight16px = () => import('../../components/icons/arrowright/ArrowRight16px.vue' /* webpackChunkName: "components/icons-arrowright-arrow-right16px" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowrightArrowRight24px = () => import('../../components/icons/arrowright/ArrowRight24px.vue' /* webpackChunkName: "components/icons-arrowright-arrow-right24px" */).then(c => wrapFunctional(c.default || c))
export const IconsCameraIcon16px = () => import('../../components/icons/camera/CameraIcon16px.vue' /* webpackChunkName: "components/icons-camera-icon16px" */).then(c => wrapFunctional(c.default || c))
export const IconsCameraIcon24px = () => import('../../components/icons/camera/CameraIcon24px.vue' /* webpackChunkName: "components/icons-camera-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsCartIcon16px = () => import('../../components/icons/cart/CartIcon16px.vue' /* webpackChunkName: "components/icons-cart-icon16px" */).then(c => wrapFunctional(c.default || c))
export const IconsCartIcon24px = () => import('../../components/icons/cart/CartIcon24px.vue' /* webpackChunkName: "components/icons-cart-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsCartIcon32px = () => import('../../components/icons/cart/CartIcon32px.vue' /* webpackChunkName: "components/icons-cart-icon32px" */).then(c => wrapFunctional(c.default || c))
export const IconsCartStack1Icon24px = () => import('../../components/icons/cart-stack-1/CartStack1Icon24px.vue' /* webpackChunkName: "components/icons-cart-stack1-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsCartStack2Icon24px = () => import('../../components/icons/cart-stack-2/CartStack2Icon24px.vue' /* webpackChunkName: "components/icons-cart-stack2-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsCheckCircle24px = () => import('../../components/icons/check-circle/CheckCircle24px.vue' /* webpackChunkName: "components/icons-check-circle24px" */).then(c => wrapFunctional(c.default || c))
export const IconsCheckboxOff20px = () => import('../../components/icons/checkbox-off/CheckboxOff20px.vue' /* webpackChunkName: "components/icons-checkbox-off20px" */).then(c => wrapFunctional(c.default || c))
export const IconsCheckboxOn20px = () => import('../../components/icons/checkbox-on/CheckboxOn20px.vue' /* webpackChunkName: "components/icons-checkbox-on20px" */).then(c => wrapFunctional(c.default || c))
export const IconsCheckmark12px = () => import('../../components/icons/checkmark/Checkmark12px.vue' /* webpackChunkName: "components/icons-checkmark12px" */).then(c => wrapFunctional(c.default || c))
export const IconsCheckmark16px = () => import('../../components/icons/checkmark/Checkmark16px.vue' /* webpackChunkName: "components/icons-checkmark16px" */).then(c => wrapFunctional(c.default || c))
export const IconsCheckmark24px = () => import('../../components/icons/checkmark/Checkmark24px.vue' /* webpackChunkName: "components/icons-checkmark24px" */).then(c => wrapFunctional(c.default || c))
export const IconsCheckmarkCircle = () => import('../../components/icons/checkmark-circle/CheckmarkCircle.vue' /* webpackChunkName: "components/icons-checkmark-circle" */).then(c => wrapFunctional(c.default || c))
export const IconsChevronDownIcon16px = () => import('../../components/icons/chevron-down/ChevronDownIcon16px.vue' /* webpackChunkName: "components/icons-chevron-down-icon16px" */).then(c => wrapFunctional(c.default || c))
export const IconsChevronDownIcon24px = () => import('../../components/icons/chevron-down/ChevronDownIcon24px.vue' /* webpackChunkName: "components/icons-chevron-down-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsChevronLeft16px = () => import('../../components/icons/chevron-left/ChevronLeft16px.vue' /* webpackChunkName: "components/icons-chevron-left16px" */).then(c => wrapFunctional(c.default || c))
export const IconsChevronLeft24px = () => import('../../components/icons/chevron-left/ChevronLeft24px.vue' /* webpackChunkName: "components/icons-chevron-left24px" */).then(c => wrapFunctional(c.default || c))
export const IconsChevronRight16px = () => import('../../components/icons/chevron-right/ChevronRight16px.vue' /* webpackChunkName: "components/icons-chevron-right16px" */).then(c => wrapFunctional(c.default || c))
export const IconsChevronRight24px = () => import('../../components/icons/chevron-right/ChevronRight24px.vue' /* webpackChunkName: "components/icons-chevron-right24px" */).then(c => wrapFunctional(c.default || c))
export const IconsChevronUpIcon16px = () => import('../../components/icons/chevron-up/ChevronUpIcon16px.vue' /* webpackChunkName: "components/icons-chevron-up-icon16px" */).then(c => wrapFunctional(c.default || c))
export const IconsChevronUpIcon24px = () => import('../../components/icons/chevron-up/ChevronUpIcon24px.vue' /* webpackChunkName: "components/icons-chevron-up-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsClockIcon24px = () => import('../../components/icons/clock/ClockIcon24px.vue' /* webpackChunkName: "components/icons-clock-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsCreditCardIcon24px = () => import('../../components/icons/credit-card/CreditCardIcon24px.vue' /* webpackChunkName: "components/icons-credit-card-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsCross16px = () => import('../../components/icons/cross/Cross16px.vue' /* webpackChunkName: "components/icons-cross16px" */).then(c => wrapFunctional(c.default || c))
export const IconsCross24px = () => import('../../components/icons/cross/Cross24px.vue' /* webpackChunkName: "components/icons-cross24px" */).then(c => wrapFunctional(c.default || c))
export const IconsCrossCircle24px = () => import('../../components/icons/cross-circle/CrossCircle24px.vue' /* webpackChunkName: "components/icons-cross-circle24px" */).then(c => wrapFunctional(c.default || c))
export const IconsDocument24px = () => import('../../components/icons/document/Document24px.vue' /* webpackChunkName: "components/icons-document24px" */).then(c => wrapFunctional(c.default || c))
export const IconsEditIcon = () => import('../../components/icons/edit/EditIcon.vue' /* webpackChunkName: "components/icons-edit-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsExchangeVertical24px = () => import('../../components/icons/exchange-vertical/ExchangeVertical24px.vue' /* webpackChunkName: "components/icons-exchange-vertical24px" */).then(c => wrapFunctional(c.default || c))
export const IconsEye16px = () => import('../../components/icons/eye/Eye16px.vue' /* webpackChunkName: "components/icons-eye16px" */).then(c => wrapFunctional(c.default || c))
export const IconsEyeOff16px = () => import('../../components/icons/eye-off/EyeOff16px.vue' /* webpackChunkName: "components/icons-eye-off16px" */).then(c => wrapFunctional(c.default || c))
export const IconsFilter16px = () => import('../../components/icons/filter/Filter16px.vue' /* webpackChunkName: "components/icons-filter16px" */).then(c => wrapFunctional(c.default || c))
export const IconsFilter24px = () => import('../../components/icons/filter/Filter24px.vue' /* webpackChunkName: "components/icons-filter24px" */).then(c => wrapFunctional(c.default || c))
export const IconsGlobeIcon14px = () => import('../../components/icons/globe/GlobeIcon14px.vue' /* webpackChunkName: "components/icons-globe-icon14px" */).then(c => wrapFunctional(c.default || c))
export const IconsGlobeIcon16px = () => import('../../components/icons/globe/GlobeIcon16px.vue' /* webpackChunkName: "components/icons-globe-icon16px" */).then(c => wrapFunctional(c.default || c))
export const IconsGlobeIcon24px = () => import('../../components/icons/globe/GlobeIcon24px.vue' /* webpackChunkName: "components/icons-globe-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsHamburgerIcon24px = () => import('../../components/icons/hamburger/HamburgerIcon24px.vue' /* webpackChunkName: "components/icons-hamburger-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsHamburgerIcon32px = () => import('../../components/icons/hamburger/HamburgerIcon32px.vue' /* webpackChunkName: "components/icons-hamburger-icon32px" */).then(c => wrapFunctional(c.default || c))
export const IconsHeartIcon24px = () => import('../../components/icons/heart/HeartIcon24px.vue' /* webpackChunkName: "components/icons-heart-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsHeartIcon32px = () => import('../../components/icons/heart/HeartIcon32px.vue' /* webpackChunkName: "components/icons-heart-icon32px" */).then(c => wrapFunctional(c.default || c))
export const IconsIdealIcon32px = () => import('../../components/icons/ideal/IdealIcon32px.vue' /* webpackChunkName: "components/icons-ideal-icon32px" */).then(c => wrapFunctional(c.default || c))
export const IconsIdealIconBW32px = () => import('../../components/icons/ideal/IdealIconBW32px.vue' /* webpackChunkName: "components/icons-ideal-icon-b-w32px" */).then(c => wrapFunctional(c.default || c))
export const IconsInfoCircle16px = () => import('../../components/icons/info-circle/InfoCircle16px.vue' /* webpackChunkName: "components/icons-info-circle16px" */).then(c => wrapFunctional(c.default || c))
export const IconsInfoCircle24px = () => import('../../components/icons/info-circle/InfoCircle24px.vue' /* webpackChunkName: "components/icons-info-circle24px" */).then(c => wrapFunctional(c.default || c))
export const IconsLeafIcon16px = () => import('../../components/icons/leaf/LeafIcon16px.vue' /* webpackChunkName: "components/icons-leaf-icon16px" */).then(c => wrapFunctional(c.default || c))
export const IconsLeafIcon24px = () => import('../../components/icons/leaf/LeafIcon24px.vue' /* webpackChunkName: "components/icons-leaf-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsLink24px = () => import('../../components/icons/link/Link24px.vue' /* webpackChunkName: "components/icons-link24px" */).then(c => wrapFunctional(c.default || c))
export const IconsLocationIcon = () => import('../../components/icons/location/LocationIcon.vue' /* webpackChunkName: "components/icons-location-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsLock = () => import('../../components/icons/lock/Lock.vue' /* webpackChunkName: "components/icons-lock" */).then(c => wrapFunctional(c.default || c))
export const IconsMastercardIcon26pxH = () => import('../../components/icons/mastercard/MastercardIcon26pxH.vue' /* webpackChunkName: "components/icons-mastercard-icon26px-h" */).then(c => wrapFunctional(c.default || c))
export const IconsMastercardIconBW26pxH = () => import('../../components/icons/mastercard/MastercardIconBW26pxH.vue' /* webpackChunkName: "components/icons-mastercard-icon-b-w26px-h" */).then(c => wrapFunctional(c.default || c))
export const IconsMinusIcon12px = () => import('../../components/icons/minus/MinusIcon12px.vue' /* webpackChunkName: "components/icons-minus-icon12px" */).then(c => wrapFunctional(c.default || c))
export const IconsMinusIcon16px = () => import('../../components/icons/minus/MinusIcon16px.vue' /* webpackChunkName: "components/icons-minus-icon16px" */).then(c => wrapFunctional(c.default || c))
export const IconsPaypalIcon15pxH = () => import('../../components/icons/paypal/PaypalIcon15pxH.vue' /* webpackChunkName: "components/icons-paypal-icon15px-h" */).then(c => wrapFunctional(c.default || c))
export const IconsPaypalIconBW15pxH = () => import('../../components/icons/paypal/PaypalIconBW15pxH.vue' /* webpackChunkName: "components/icons-paypal-icon-b-w15px-h" */).then(c => wrapFunctional(c.default || c))
export const IconsPencil24px = () => import('../../components/icons/pencil/Pencil24px.vue' /* webpackChunkName: "components/icons-pencil24px" */).then(c => wrapFunctional(c.default || c))
export const IconsPercentage24px = () => import('../../components/icons/percentage/Percentage24px.vue' /* webpackChunkName: "components/icons-percentage24px" */).then(c => wrapFunctional(c.default || c))
export const IconsPercentage33px = () => import('../../components/icons/percentage/Percentage33px.vue' /* webpackChunkName: "components/icons-percentage33px" */).then(c => wrapFunctional(c.default || c))
export const IconsPhone = () => import('../../components/icons/phone/Phone.vue' /* webpackChunkName: "components/icons-phone" */).then(c => wrapFunctional(c.default || c))
export const IconsPlayIcon24px = () => import('../../components/icons/play/PlayIcon24px.vue' /* webpackChunkName: "components/icons-play-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsPlusIcon12px = () => import('../../components/icons/plus/PlusIcon12px.vue' /* webpackChunkName: "components/icons-plus-icon12px" */).then(c => wrapFunctional(c.default || c))
export const IconsPlusIcon16px = () => import('../../components/icons/plus/PlusIcon16px.vue' /* webpackChunkName: "components/icons-plus-icon16px" */).then(c => wrapFunctional(c.default || c))
export const IconsPlusIcon24px = () => import('../../components/icons/plus/PlusIcon24px.vue' /* webpackChunkName: "components/icons-plus-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsPostNl = () => import('../../components/icons/post-nl/PostNl.vue' /* webpackChunkName: "components/icons-post-nl" */).then(c => wrapFunctional(c.default || c))
export const IconsPrint24px = () => import('../../components/icons/print/Print24px.vue' /* webpackChunkName: "components/icons-print24px" */).then(c => wrapFunctional(c.default || c))
export const IconsRefreshIcon24px = () => import('../../components/icons/refresh/RefreshIcon24px.vue' /* webpackChunkName: "components/icons-refresh-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsSearch16px = () => import('../../components/icons/search/Search16px.vue' /* webpackChunkName: "components/icons-search16px" */).then(c => wrapFunctional(c.default || c))
export const IconsSearch24px = () => import('../../components/icons/search/Search24px.vue' /* webpackChunkName: "components/icons-search24px" */).then(c => wrapFunctional(c.default || c))
export const IconsShare24px = () => import('../../components/icons/share/Share24px.vue' /* webpackChunkName: "components/icons-share24px" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialFacebook = () => import('../../components/icons/social/Facebook.vue' /* webpackChunkName: "components/icons-social-facebook" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialInstagram = () => import('../../components/icons/social/Instagram.vue' /* webpackChunkName: "components/icons-social-instagram" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialLinkedin = () => import('../../components/icons/social/Linkedin.vue' /* webpackChunkName: "components/icons-social-linkedin" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialMail = () => import('../../components/icons/social/Mail.vue' /* webpackChunkName: "components/icons-social-mail" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialStores = () => import('../../components/icons/social/Stores.vue' /* webpackChunkName: "components/icons-social-stores" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialStoresIcon24px = () => import('../../components/icons/social/StoresIcon24px.vue' /* webpackChunkName: "components/icons-social-stores-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialTwitter = () => import('../../components/icons/social/Twitter.vue' /* webpackChunkName: "components/icons-social-twitter" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialWhatsApp = () => import('../../components/icons/social/WhatsApp.vue' /* webpackChunkName: "components/icons-social-whats-app" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialYoutube = () => import('../../components/icons/social/Youtube.vue' /* webpackChunkName: "components/icons-social-youtube" */).then(c => wrapFunctional(c.default || c))
export const IconsSpinner = () => import('../../components/icons/spinner/Spinner.vue' /* webpackChunkName: "components/icons-spinner" */).then(c => wrapFunctional(c.default || c))
export const IconsStarIcon16px = () => import('../../components/icons/star/StarIcon16px.vue' /* webpackChunkName: "components/icons-star-icon16px" */).then(c => wrapFunctional(c.default || c))
export const IconsThumbUpIcon24px = () => import('../../components/icons/thumb-up/ThumbUpIcon24px.vue' /* webpackChunkName: "components/icons-thumb-up-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsTrash = () => import('../../components/icons/trash/Trash.vue' /* webpackChunkName: "components/icons-trash" */).then(c => wrapFunctional(c.default || c))
export const IconsTrash24px = () => import('../../components/icons/trash/Trash24px.vue' /* webpackChunkName: "components/icons-trash24px" */).then(c => wrapFunctional(c.default || c))
export const IconsTruckIcon24px = () => import('../../components/icons/truck/TruckIcon24px.vue' /* webpackChunkName: "components/icons-truck-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsUserIcon24px = () => import('../../components/icons/user/UserIcon24px.vue' /* webpackChunkName: "components/icons-user-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsUserIcon32px = () => import('../../components/icons/user/UserIcon32px.vue' /* webpackChunkName: "components/icons-user-icon32px" */).then(c => wrapFunctional(c.default || c))
export const IconsVerifiedIcon24px = () => import('../../components/icons/verified/VerifiedIcon24px.vue' /* webpackChunkName: "components/icons-verified-icon24px" */).then(c => wrapFunctional(c.default || c))
export const IconsVisaIcon16pxH = () => import('../../components/icons/visa/VisaIcon16pxH.vue' /* webpackChunkName: "components/icons-visa-icon16px-h" */).then(c => wrapFunctional(c.default || c))
export const IconsVisaIconBW16pxH = () => import('../../components/icons/visa/VisaIconBW16pxH.vue' /* webpackChunkName: "components/icons-visa-icon-b-w16px-h" */).then(c => wrapFunctional(c.default || c))
export const StoreDetailsAddress = () => import('../../components/store/details/Address.vue' /* webpackChunkName: "components/store-details-address" */).then(c => wrapFunctional(c.default || c))
export const StoreDetailsBanner = () => import('../../components/store/details/Banner.vue' /* webpackChunkName: "components/store-details-banner" */).then(c => wrapFunctional(c.default || c))
export const StoreDetailsFacilities = () => import('../../components/store/details/Facilities.vue' /* webpackChunkName: "components/store-details-facilities" */).then(c => wrapFunctional(c.default || c))
export const StoreDetailsSchedule = () => import('../../components/store/details/Schedule.vue' /* webpackChunkName: "components/store-details-schedule" */).then(c => wrapFunctional(c.default || c))
export const StoreDetailsScheduleAccesibility = () => import('../../components/store/details/ScheduleAccesibility.vue' /* webpackChunkName: "components/store-details-schedule-accesibility" */).then(c => wrapFunctional(c.default || c))
export const StoreDetailsScheduleOpeningHours = () => import('../../components/store/details/ScheduleOpeningHours.vue' /* webpackChunkName: "components/store-details-schedule-opening-hours" */).then(c => wrapFunctional(c.default || c))
export const StoreDetailsScheduleSpecialHours = () => import('../../components/store/details/ScheduleSpecialHours.vue' /* webpackChunkName: "components/store-details-schedule-special-hours" */).then(c => wrapFunctional(c.default || c))
export const StoreListGrid = () => import('../../components/store/list/Grid.vue' /* webpackChunkName: "components/store-list-grid" */).then(c => wrapFunctional(c.default || c))
export const StoreListGridItem = () => import('../../components/store/list/GridItem.vue' /* webpackChunkName: "components/store-list-grid-item" */).then(c => wrapFunctional(c.default || c))
export const StoreSharedIntro = () => import('../../components/store/shared/Intro.vue' /* webpackChunkName: "components/store-shared-intro" */).then(c => wrapFunctional(c.default || c))
export const StoreSharedMap = () => import('../../components/store/shared/Map.vue' /* webpackChunkName: "components/store-shared-map" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMiniCartItem = () => import('../../components/molecules/MiniCart/CartItem.vue' /* webpackChunkName: "components/molecules-mini-cart-item" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMiniCart = () => import('../../components/molecules/MiniCart/MiniCart.vue' /* webpackChunkName: "components/molecules-mini-cart" */).then(c => wrapFunctional(c.default || c))
export const MoleculesUspsBoxUsp = () => import('../../components/molecules/Usps/BoxUsp.vue' /* webpackChunkName: "components/molecules-usps-box-usp" */).then(c => wrapFunctional(c.default || c))
export const MoleculesUspsCheckmarkUsp = () => import('../../components/molecules/Usps/CheckmarkUsp.vue' /* webpackChunkName: "components/molecules-usps-checkmark-usp" */).then(c => wrapFunctional(c.default || c))
export const MoleculesUspsIconUsp = () => import('../../components/molecules/Usps/IconUsp.vue' /* webpackChunkName: "components/molecules-usps-icon-usp" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsServiceSectionCard = () => import('../../components/cms-components/ServiceSection/Card.vue' /* webpackChunkName: "components/cms-components-service-section-card" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsServiceSectionGallery = () => import('../../components/cms-components/ServiceSection/Gallery.vue' /* webpackChunkName: "components/cms-components-service-section-gallery" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsBasicBanner = () => import('../../components/cms-components/basic-banner/BasicBanner.vue' /* webpackChunkName: "components/cms-components-basic-banner" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsBasicBannerContainer = () => import('../../components/cms-components/basic-banner/Container.vue' /* webpackChunkName: "components/cms-components-basic-banner-container" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsBrandLogoHighlights = () => import('../../components/cms-components/brand-logo-highlights/BrandLogoHighlights.vue' /* webpackChunkName: "components/cms-components-brand-logo-highlights" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsBrandLogoHighlightsLogosOnly = () => import('../../components/cms-components/brand-logo-highlights/LogosOnly.vue' /* webpackChunkName: "components/cms-components-brand-logo-highlights-logos-only" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsBrandLogoHighlightsTextOnly = () => import('../../components/cms-components/brand-logo-highlights/TextOnly.vue' /* webpackChunkName: "components/cms-components-brand-logo-highlights-text-only" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsCategoryHighlightsGallery = () => import('../../components/cms-components/category-highlights/Gallery.vue' /* webpackChunkName: "components/cms-components-category-highlights-gallery" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsCategoryHighlightsGalleryCard = () => import('../../components/cms-components/category-highlights/GalleryCard.vue' /* webpackChunkName: "components/cms-components-category-highlights-gallery-card" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsContactCard = () => import('../../components/cms-components/contact/ContactCard.vue' /* webpackChunkName: "components/cms-components-contact-card" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsContactServiceCard = () => import('../../components/cms-components/contact/ServiceCard.vue' /* webpackChunkName: "components/cms-components-contact-service-card" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsExpertStoriesGallery = () => import('../../components/cms-components/expert-stories/Gallery.vue' /* webpackChunkName: "components/cms-components-expert-stories-gallery" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsExpertStoriesGalleryCard = () => import('../../components/cms-components/expert-stories/GalleryCard.vue' /* webpackChunkName: "components/cms-components-expert-stories-gallery-card" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsFaqContainer = () => import('../../components/cms-components/faq/Container.vue' /* webpackChunkName: "components/cms-components-faq-container" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsFaq = () => import('../../components/cms-components/faq/Faq.vue' /* webpackChunkName: "components/cms-components-faq" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsInspirationalTilesGallery = () => import('../../components/cms-components/inspirational-tiles/Gallery.vue' /* webpackChunkName: "components/cms-components-inspirational-tiles-gallery" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsInspirationalTile = () => import('../../components/cms-components/inspirational-tiles/InspirationalTile.vue' /* webpackChunkName: "components/cms-components-inspirational-tile" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsProductCarouselGallery = () => import('../../components/cms-components/product-carousel/Gallery.vue' /* webpackChunkName: "components/cms-components-product-carousel-gallery" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsUspBarContainer = () => import('../../components/cms-components/usp-bar/Container.vue' /* webpackChunkName: "components/cms-components-usp-bar-container" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentsUspBarItem = () => import('../../components/cms-components/usp-bar/Item.vue' /* webpackChunkName: "components/cms-components-usp-bar-item" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
