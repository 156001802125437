type CartItem = {
  id: number;
  name: string;
  price: number;
  quantity: number;
  thumbnail: string;
};

export type CartType = {
  cartItems: Array<CartItem>;
  itemsToAdd: Array<Object>;
  cartTotalPrice: number;
};

const state = () =>
  ({
    cartItems: [],
    itemsToAdd: [],
    cartTotalPrice: 0
  } as CartType);

const getters = {
  getCartItems: () => {
    return state().cartItems;
  },
  getItemsToAdd: () => {
    return state().itemsToAdd;
  },
  getCartTotalPrice: () => {
    return state().cartTotalPrice;
  }
};

const actions = {
  async nuxtServerInit({ commit }, context) {
    // Do something on server initialised
  },

  // used by MiniCart to sync cart from backend
  async syncCart({ commit }, { items, totalPrice }) {
    commit('SET_CART_ITEMS', items);
    commit('SET_CART_TOTAL_PRICE', totalPrice);
    commit('CLEAR_ITEMS_TO_ADD');
  },

  // used by all other components to add items to cart
  async addToCart({ commit }, items) {
    commit('ADD_ITEMS_TO_ADD', items);
  }
};

const mutations = {
  ADD_ITEMS_TO_ADD(state: CartType, items: Array<Object>) {
    state.itemsToAdd = [...state.itemsToAdd, ...items];
  },
  CLEAR_ITEMS_TO_ADD(state: CartType) {
    state.itemsToAdd = [];
  },
  SET_CART_ITEMS(state: CartType, cartItems: Array<CartItem>) {
    state.cartItems = cartItems;
  },
  SET_CART_TOTAL_PRICE(state: CartType, cartTotalPrice: number) {
    state.cartTotalPrice = cartTotalPrice;
  }
};

const Cart = {
  state,
  mutations,
  actions,
  getters
};

export default Cart;
