import { render, staticRenderFns } from "./error.vue?vue&type=template&id=464f2c4a&scoped=true&"
import script from "./error.vue?vue&type=script&lang=ts&"
export * from "./error.vue?vue&type=script&lang=ts&"
import style0 from "./error.vue?vue&type=style&index=0&id=464f2c4a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "464f2c4a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsKEButton: require('/var/www/packages/theme/components/atoms/KEButton.vue').default,IconsChevronRight24px: require('/var/www/packages/theme/components/icons/chevron-right/ChevronRight24px.vue').default})
