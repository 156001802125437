
























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';

export type ButtonVariant =
  | 'primary'
  | 'primary-black'
  | 'secondary'
  | 'tertiary'
  | 'tertiary-no-underline';

export default defineComponent({
  name: 'KEButton',
  components: {
    SfButton
  },
  props: {
    variant: {
      type: String as PropType<ButtonVariant>,
      default: 'primary'
    },
    small: { type: Boolean, default: false },
    iconOnly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    loadingMessage: { type: String, required: false, default: 'Loading...' },
    fullWidth: { type: Boolean, default: false }
  }
});
