type urlInfoType = {
  type: string;
  id: number;
  breadcrumb: any;
  product_id: number;
  spli_id: number;
  product_tag_id: number;
  page_type: 'plp' | 'pdp';
  containers: any[];
  cmswebsiteredirects_headless_pages: any[];
};

type pageType = {
  urlInfo: urlInfoType;
};

const state = () => ({
  urlInfo: {
    type: '',
    id: undefined,
    breadcrumb: [],
    product_id: undefined,
    spli_id: undefined,
    product_tag_id: undefined,
    page_type: undefined,
    containers: [],
    cmswebsiteredirects_headless_pages: []
  }
});

const getters = {
  getUrlInfo: (state: pageType) => {
    return state.urlInfo;
  },
  getPageType: (state: pageType) => {
    return state?.urlInfo.page_type;
  },
  getPageId: (state: pageType) => {
    return state?.urlInfo.product_tag_id;
  },
  getBreadcrumb: (state: pageType) => {
    return state?.urlInfo.breadcrumb;
  }
};

const actions = {
  async setUrlInfo({ commit }, urlInfo: urlInfoType) {
    commit('URLINFO', urlInfo);
  }
};

const mutations = {
  URLINFO(state: pageType, urlInfo: urlInfoType) {
    state.urlInfo = urlInfo;
  }
};

const Page = {
  state,
  mutations,
  actions,
  getters
};

export default Page;
