const createScriptElement = ({ trustedshopsId }) => {
  const scriptElement = document.createElement('script');
  scriptElement.dataset.desktopYOffset = '0';
  scriptElement.dataset.mobileYOffset = '0';
  scriptElement.dataset.desktopDisableReviews = 'false';
  scriptElement.dataset.desktopEnableCustom = 'false';
  scriptElement.dataset.desktopPosition = 'right';
  scriptElement.dataset.desktopCustomWidth = '156';
  scriptElement.dataset.desktopEnableFadeout = 'false';
  scriptElement.dataset.disableMobile = 'false';
  scriptElement.dataset.disableTrustbadge = 'false';
  scriptElement.dataset.mobileCustomWidth = '156';
  scriptElement.dataset.mobileDisableReviews = 'false';
  scriptElement.dataset.mobileEnableCustom = 'false';
  scriptElement.dataset.mobilePosition = 'left';
  scriptElement.dataset.mobileEnableTopbar = 'false';
  scriptElement.dataset.mobileEnableFadeout = 'true';
  scriptElement.dataset.colorScheme = 'light';
  scriptElement.charset = 'UTF-8';
  scriptElement.src = `//widgets.trustedshops.com/js/${trustedshopsId}.js`;
  scriptElement.async = true;
  scriptElement.defer = true;
  return scriptElement;
};

export default (context) => {
  const locale = context.i18n.locale;
  const domainConfig = context.$config.domainConfig;
  const trustedshopsId = domainConfig.lang[locale]?.trustedshopsId;
  if (!trustedshopsId) {
    return;
  }
  const scriptElement = createScriptElement({ trustedshopsId });
  document.body.appendChild(scriptElement);
};
