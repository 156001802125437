import { IncomingMessage as Request, ServerResponse as Response } from 'http';
import { NovuloState } from '../types';
import cookie from '../cookie';

export function createNovuloState(req?: Request, res?: Response): NovuloState {
  const cookies = cookie(req, res);

  /*
   * N.B. keep the header name lower case. Node.js represents all headers with lower case keys in req.headers
   */
  const cartHeaderName = 'x-vsf-novulocart';
  // TODO onno cart cookiename configurable & check current value
  const cartCookieName = 'n_shopping_cart';
  const customerHeaderName = 'x-vsf-novulocustomer';
  const wtHeaderName = 'x-vsf-wt';
  const salesChannelHeaderName = 'x-vsf-sales-channel';
  const languageHeaderName = 'x-vsf-language';
  // TODO onno customer cookiename configurable & check current value
  const customerCookieName = 'novulocustomer';
  const novuloAuthCookie = '.ASPX_NOVULO_AUTH';
  const resolve = (headerName: string, cookieName: string) => {
    return req && req.headers[headerName] !== undefined
      ? req.headers[headerName]
      : cookies.get(cookieName);
  };

  return {
    cartId: resolve(cartHeaderName, cartCookieName),
    customerToken: resolve(customerHeaderName, customerCookieName),
    wt: req?.headers[wtHeaderName],
    salesChannel: req?.headers[salesChannelHeaderName],
    language: req?.headers[languageHeaderName],
    novuloAuthCookie: resolve(null, novuloAuthCookie),
    getCartHeaderName: function () {
      return cartHeaderName;
    },
    getCartCookieName: function () {
      return cartCookieName;
    },
    getCustomerHeaderName: function () {
      return customerHeaderName;
    },
    getCustomerCookieName: function () {
      return customerCookieName;
    },
    getWtHeaderName: function () {
      return wtHeaderName;
    },
    getSalesChannelHeaderName: function () {
      return salesChannelHeaderName;
    },
    getLanguageHeaderName: function () {
      return languageHeaderName;
    },
    getCartId: function () {
      return this.cartId;
    },
    getWt: function () {
      return this.wt;
    },
    getSalesChannel: function () {
      return this.salesChannel;
    },
    getLanguage: function () {
      return this.language;
    },
    getDomainConfig: function () {
      return this.domainConfig;
    },
    getTweakwiseInstanceKey: function () {
      return this.tweakwiseInstanceKey;
    },
    getNovuloAuthCookie: function () {
      return this.novuloAuthCookie;
    },
    setCartId: function (cartId: string) {
      this.cartId = cartId;
      if (cartId) {
        cookies.set(this.getCartCookieName(), cartId);
      } else {
        cookies.remove(this.getCartCookieName());
      }
    },
    removeCartId: function () {
      this.setCartId(null);
    },
    getCustomerToken: function () {
      return this.customerToken;
    },
    setCustomerToken: function (token: string) {
      if (token) {
        this.customerToken = token;
      } else {
        this.customerToken = null;
        cookies.remove(this.getCustomerCookieName());
      }
    },
    setWt: function (wt) {
      this.wt = wt;
    },
    setSalesChannel: function (salesChannel) {
      this.salesChannel = salesChannel;
    },
    setLanguage: function (language) {
      this.language = language;
    },
    setDomainConfig: function (domainConfig) {
      this.domainConfig = domainConfig;
    },
    setTweakwiseInstanceKey: function (tweakwiseInstanceKey) {
      this.tweakwiseInstanceKey = tweakwiseInstanceKey;
    },
    setNovuloAuthCookie: (cookie: string) => {
      res.setHeader(
        'Set-Cookie',
        `${novuloAuthCookie}=${cookie}; HttpOnly; Path=/`
      );
    },
    removeCustomerToken: function () {
      this.setCustomerToken(null);
    }
  } as NovuloState;
}
