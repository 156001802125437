const skipList = ['/media/'];

function skip(url) {
  return skipList.find((entry) => url.startsWith(entry)) != undefined;
}

export function resolveDomainConfig({ req, $config }) {
  const domainList = $config.domainList;
  let domainConfig = domainList[getHost(req)];
  if (!domainConfig) {
    const defaultDomain = Object.keys(domainList)[0];
    domainConfig = domainList[defaultDomain];
  }
  return domainConfig;
}

function getHost(req) {
  let host;
  if (process.server) {
    host = req.headers.host;
  } else {
    host = window.location.hostname;
  }
  host = host.split(':')[0];
  return host.startsWith('www.') ? host.split('www.')[1] : host;
}

export default function (context) {
  const req = context.req;
  const $config = context.$config;
  if (process.server && skip(req.url)) {
    return;
  }
  const domainConfig = resolveDomainConfig({ req, $config });
  $config.domainConfig = domainConfig;
}
