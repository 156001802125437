/* this store is used to transfer GTM data from the Nuxt instance to the external script "init-gtm" */
/* the data is passed through the head meta tags as the external script loads on the client before the Nuxt instance */
/* we use an external script as we want GTM to load as soon as possible */

const state = () => ({
  gtmId: '',
  pageLanguage: '',
  extraParams: {}
});

const getters = {
  gtmId: (state) => state.gtmId,
  pageLanguage: (state) => state.pageLanguage,
  extraParams: (state) => state.extraParams
};

const mutations = {
  setGtmId(state, gtmId) {
    state.gtmId = gtmId;
  },
  setPageLanguage(state, pageLanguage) {
    state.pageLanguage = pageLanguage;
  },
  setExtraParams(state, extraParams) {
    state.extraParams = extraParams;
  }
};

const actions = {
  setGtmId({ commit }, gtmId) {
    commit('setGtmId', gtmId);
  },
  setPageLanguage({ commit }, pageLanguage) {
    commit('setPageLanguage', pageLanguage);
  },
  setExtraParams({ commit }, extraParams) {
    commit('setExtraParams', extraParams);
  }
};

const GTMInit = {
  state,
  getters,
  mutations,
  actions
};

export default GTMInit;
