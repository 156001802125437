import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0be5bcf7 = () => interopDefault(import('../_theme/pages/Category.vue' /* webpackChunkName: "_theme/pages/Category" */))
const _06696c3b = () => interopDefault(import('../pages/redirecter.vue' /* webpackChunkName: "_theme/pages/Checkout" */))
const _4e1ac38b = () => interopDefault(import('../_theme/pages/Checkout/Billing.vue' /* webpackChunkName: "_theme/pages/Checkout/Billing" */))
const _266bd4d4 = () => interopDefault(import('../_theme/pages/Checkout/Payment.vue' /* webpackChunkName: "_theme/pages/Checkout/Payment" */))
const _96103764 = () => interopDefault(import('../_theme/pages/Checkout/Shipping.vue' /* webpackChunkName: "_theme/pages/Checkout/Shipping" */))
const _c2f4a016 = () => interopDefault(import('../_theme/pages/Checkout/ThankYou.vue' /* webpackChunkName: "_theme/pages/Checkout/ThankYou" */))
const _0ce1f3f4 = () => interopDefault(import('../_theme/pages/grid-prototype.vue' /* webpackChunkName: "_theme/pages/grid-prototype" */))
const _572b1650 = () => interopDefault(import('../_theme/pages/Home.vue' /* webpackChunkName: "_theme/pages/Home" */))
const _270a84b8 = () => interopDefault(import('../_theme/pages/MyAccount.vue' /* webpackChunkName: "_theme/pages/MyAccount" */))
const _4984c6ce = () => interopDefault(import('../_theme/pages/MyAccount/BillingDetails.vue' /* webpackChunkName: "_theme/pages/MyAccount/BillingDetails" */))
const _171c5068 = () => interopDefault(import('../_theme/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "_theme/pages/MyAccount/MyNewsletter" */))
const _5f1896b4 = () => interopDefault(import('../_theme/pages/MyAccount/MyProfile.vue' /* webpackChunkName: "_theme/pages/MyAccount/MyProfile" */))
const _60920da6 = () => interopDefault(import('../_theme/pages/MyAccount/OrderHistory.vue' /* webpackChunkName: "_theme/pages/MyAccount/OrderHistory" */))
const _d2210a86 = () => interopDefault(import('../_theme/pages/MyAccount/ShippingDetails.vue' /* webpackChunkName: "_theme/pages/MyAccount/ShippingDetails" */))
const _3f3438f4 = () => interopDefault(import('../_theme/pages/Product.vue' /* webpackChunkName: "_theme/pages/Product" */))
const _b21840bc = () => interopDefault(import('../_theme/pages/redirecter.vue' /* webpackChunkName: "_theme/pages/redirecter" */))
const _77c59a3e = () => interopDefault(import('../_theme/pages/ResetPassword.vue' /* webpackChunkName: "_theme/pages/ResetPassword" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de/Category",
    component: _0be5bcf7,
    name: "Category___de"
  }, {
    path: "/de/Checkout",
    component: _06696c3b,
    name: "Checkout___de",
    children: [{
      path: "Billing",
      component: _4e1ac38b,
      name: "Checkout-Billing___de"
    }, {
      path: "Payment",
      component: _266bd4d4,
      name: "Checkout-Payment___de"
    }, {
      path: "Shipping",
      component: _96103764,
      name: "Checkout-Shipping___de"
    }, {
      path: "ThankYou",
      component: _c2f4a016,
      name: "Checkout-ThankYou___de"
    }]
  }, {
    path: "/de/grid-prototype",
    component: _0ce1f3f4,
    name: "grid-prototype___de"
  }, {
    path: "/de/Home",
    component: _572b1650,
    name: "Home___de"
  }, {
    path: "/de/MyAccount",
    component: _270a84b8,
    name: "MyAccount___de",
    children: [{
      path: "BillingDetails",
      component: _4984c6ce,
      name: "MyAccount-BillingDetails___de"
    }, {
      path: "MyNewsletter",
      component: _171c5068,
      name: "MyAccount-MyNewsletter___de"
    }, {
      path: "MyProfile",
      component: _5f1896b4,
      name: "MyAccount-MyProfile___de"
    }, {
      path: "OrderHistory",
      component: _60920da6,
      name: "MyAccount-OrderHistory___de"
    }, {
      path: "ShippingDetails",
      component: _d2210a86,
      name: "MyAccount-ShippingDetails___de"
    }]
  }, {
    path: "/de/Product",
    component: _3f3438f4,
    name: "Product___de"
  }, {
    path: "/de/redirecter",
    component: _b21840bc,
    name: "redirecter___de"
  }, {
    path: "/de/ResetPassword",
    component: _77c59a3e,
    name: "ResetPassword___de"
  }, {
    path: "/en/Category",
    component: _0be5bcf7,
    name: "Category___en"
  }, {
    path: "/en/Checkout",
    component: _06696c3b,
    name: "Checkout___en",
    children: [{
      path: "Billing",
      component: _4e1ac38b,
      name: "Checkout-Billing___en"
    }, {
      path: "Payment",
      component: _266bd4d4,
      name: "Checkout-Payment___en"
    }, {
      path: "Shipping",
      component: _96103764,
      name: "Checkout-Shipping___en"
    }, {
      path: "ThankYou",
      component: _c2f4a016,
      name: "Checkout-ThankYou___en"
    }]
  }, {
    path: "/en/grid-prototype",
    component: _0ce1f3f4,
    name: "grid-prototype___en"
  }, {
    path: "/en/Home",
    component: _572b1650,
    name: "Home___en"
  }, {
    path: "/en/MyAccount",
    component: _270a84b8,
    name: "MyAccount___en",
    children: [{
      path: "BillingDetails",
      component: _4984c6ce,
      name: "MyAccount-BillingDetails___en"
    }, {
      path: "MyNewsletter",
      component: _171c5068,
      name: "MyAccount-MyNewsletter___en"
    }, {
      path: "MyProfile",
      component: _5f1896b4,
      name: "MyAccount-MyProfile___en"
    }, {
      path: "OrderHistory",
      component: _60920da6,
      name: "MyAccount-OrderHistory___en"
    }, {
      path: "ShippingDetails",
      component: _d2210a86,
      name: "MyAccount-ShippingDetails___en"
    }]
  }, {
    path: "/en/Product",
    component: _3f3438f4,
    name: "Product___en"
  }, {
    path: "/en/redirecter",
    component: _b21840bc,
    name: "redirecter___en"
  }, {
    path: "/en/ResetPassword",
    component: _77c59a3e,
    name: "ResetPassword___en"
  }, {
    path: "/es/Category",
    component: _0be5bcf7,
    name: "Category___es"
  }, {
    path: "/es/Checkout",
    component: _06696c3b,
    name: "Checkout___es",
    children: [{
      path: "Billing",
      component: _4e1ac38b,
      name: "Checkout-Billing___es"
    }, {
      path: "Payment",
      component: _266bd4d4,
      name: "Checkout-Payment___es"
    }, {
      path: "Shipping",
      component: _96103764,
      name: "Checkout-Shipping___es"
    }, {
      path: "ThankYou",
      component: _c2f4a016,
      name: "Checkout-ThankYou___es"
    }]
  }, {
    path: "/es/grid-prototype",
    component: _0ce1f3f4,
    name: "grid-prototype___es"
  }, {
    path: "/es/Home",
    component: _572b1650,
    name: "Home___es"
  }, {
    path: "/es/MyAccount",
    component: _270a84b8,
    name: "MyAccount___es",
    children: [{
      path: "BillingDetails",
      component: _4984c6ce,
      name: "MyAccount-BillingDetails___es"
    }, {
      path: "MyNewsletter",
      component: _171c5068,
      name: "MyAccount-MyNewsletter___es"
    }, {
      path: "MyProfile",
      component: _5f1896b4,
      name: "MyAccount-MyProfile___es"
    }, {
      path: "OrderHistory",
      component: _60920da6,
      name: "MyAccount-OrderHistory___es"
    }, {
      path: "ShippingDetails",
      component: _d2210a86,
      name: "MyAccount-ShippingDetails___es"
    }]
  }, {
    path: "/es/Product",
    component: _3f3438f4,
    name: "Product___es"
  }, {
    path: "/es/redirecter",
    component: _b21840bc,
    name: "redirecter___es"
  }, {
    path: "/es/ResetPassword",
    component: _77c59a3e,
    name: "ResetPassword___es"
  }, {
    path: "/fr/Category",
    component: _0be5bcf7,
    name: "Category___fr"
  }, {
    path: "/fr/Checkout",
    component: _06696c3b,
    name: "Checkout___fr",
    children: [{
      path: "Billing",
      component: _4e1ac38b,
      name: "Checkout-Billing___fr"
    }, {
      path: "Payment",
      component: _266bd4d4,
      name: "Checkout-Payment___fr"
    }, {
      path: "Shipping",
      component: _96103764,
      name: "Checkout-Shipping___fr"
    }, {
      path: "ThankYou",
      component: _c2f4a016,
      name: "Checkout-ThankYou___fr"
    }]
  }, {
    path: "/fr/grid-prototype",
    component: _0ce1f3f4,
    name: "grid-prototype___fr"
  }, {
    path: "/fr/Home",
    component: _572b1650,
    name: "Home___fr"
  }, {
    path: "/fr/MyAccount",
    component: _270a84b8,
    name: "MyAccount___fr",
    children: [{
      path: "BillingDetails",
      component: _4984c6ce,
      name: "MyAccount-BillingDetails___fr"
    }, {
      path: "MyNewsletter",
      component: _171c5068,
      name: "MyAccount-MyNewsletter___fr"
    }, {
      path: "MyProfile",
      component: _5f1896b4,
      name: "MyAccount-MyProfile___fr"
    }, {
      path: "OrderHistory",
      component: _60920da6,
      name: "MyAccount-OrderHistory___fr"
    }, {
      path: "ShippingDetails",
      component: _d2210a86,
      name: "MyAccount-ShippingDetails___fr"
    }]
  }, {
    path: "/fr/Product",
    component: _3f3438f4,
    name: "Product___fr"
  }, {
    path: "/fr/redirecter",
    component: _b21840bc,
    name: "redirecter___fr"
  }, {
    path: "/fr/ResetPassword",
    component: _77c59a3e,
    name: "ResetPassword___fr"
  }, {
    path: "/it/Category",
    component: _0be5bcf7,
    name: "Category___it"
  }, {
    path: "/it/Checkout",
    component: _06696c3b,
    name: "Checkout___it",
    children: [{
      path: "Billing",
      component: _4e1ac38b,
      name: "Checkout-Billing___it"
    }, {
      path: "Payment",
      component: _266bd4d4,
      name: "Checkout-Payment___it"
    }, {
      path: "Shipping",
      component: _96103764,
      name: "Checkout-Shipping___it"
    }, {
      path: "ThankYou",
      component: _c2f4a016,
      name: "Checkout-ThankYou___it"
    }]
  }, {
    path: "/it/grid-prototype",
    component: _0ce1f3f4,
    name: "grid-prototype___it"
  }, {
    path: "/it/Home",
    component: _572b1650,
    name: "Home___it"
  }, {
    path: "/it/MyAccount",
    component: _270a84b8,
    name: "MyAccount___it",
    children: [{
      path: "BillingDetails",
      component: _4984c6ce,
      name: "MyAccount-BillingDetails___it"
    }, {
      path: "MyNewsletter",
      component: _171c5068,
      name: "MyAccount-MyNewsletter___it"
    }, {
      path: "MyProfile",
      component: _5f1896b4,
      name: "MyAccount-MyProfile___it"
    }, {
      path: "OrderHistory",
      component: _60920da6,
      name: "MyAccount-OrderHistory___it"
    }, {
      path: "ShippingDetails",
      component: _d2210a86,
      name: "MyAccount-ShippingDetails___it"
    }]
  }, {
    path: "/it/Product",
    component: _3f3438f4,
    name: "Product___it"
  }, {
    path: "/it/redirecter",
    component: _b21840bc,
    name: "redirecter___it"
  }, {
    path: "/it/ResetPassword",
    component: _77c59a3e,
    name: "ResetPassword___it"
  }, {
    path: "/nl/Category",
    component: _0be5bcf7,
    name: "Category___nl"
  }, {
    path: "/nl/Checkout",
    component: _06696c3b,
    name: "Checkout___nl",
    children: [{
      path: "Billing",
      component: _4e1ac38b,
      name: "Checkout-Billing___nl"
    }, {
      path: "Payment",
      component: _266bd4d4,
      name: "Checkout-Payment___nl"
    }, {
      path: "Shipping",
      component: _96103764,
      name: "Checkout-Shipping___nl"
    }, {
      path: "ThankYou",
      component: _c2f4a016,
      name: "Checkout-ThankYou___nl"
    }]
  }, {
    path: "/nl/grid-prototype",
    component: _0ce1f3f4,
    name: "grid-prototype___nl"
  }, {
    path: "/nl/Home",
    component: _572b1650,
    name: "Home___nl"
  }, {
    path: "/nl/MyAccount",
    component: _270a84b8,
    name: "MyAccount___nl",
    children: [{
      path: "BillingDetails",
      component: _4984c6ce,
      name: "MyAccount-BillingDetails___nl"
    }, {
      path: "MyNewsletter",
      component: _171c5068,
      name: "MyAccount-MyNewsletter___nl"
    }, {
      path: "MyProfile",
      component: _5f1896b4,
      name: "MyAccount-MyProfile___nl"
    }, {
      path: "OrderHistory",
      component: _60920da6,
      name: "MyAccount-OrderHistory___nl"
    }, {
      path: "ShippingDetails",
      component: _d2210a86,
      name: "MyAccount-ShippingDetails___nl"
    }]
  }, {
    path: "/nl/Product",
    component: _3f3438f4,
    name: "Product___nl"
  }, {
    path: "/nl/redirecter",
    component: _b21840bc,
    name: "redirecter___nl"
  }, {
    path: "/nl/ResetPassword",
    component: _77c59a3e,
    name: "ResetPassword___nl"
  }, {
    path: "/de/:slug*",
    component: _06696c3b,
    name: "custom___de"
  }, {
    path: "/en/:slug*",
    component: _06696c3b,
    name: "custom___en"
  }, {
    path: "/es/:slug*",
    component: _06696c3b,
    name: "custom___es"
  }, {
    path: "/fr/:slug*",
    component: _06696c3b,
    name: "custom___fr"
  }, {
    path: "/it/:slug*",
    component: _06696c3b,
    name: "custom___it"
  }, {
    path: "/nl/:slug*",
    component: _06696c3b,
    name: "custom___nl"
  }, {
    path: "/Category",
    component: _0be5bcf7,
    name: "Category"
  }, {
    path: "/Checkout",
    component: _06696c3b,
    name: "Checkout",
    children: [{
      path: "Billing",
      component: _4e1ac38b,
      name: "Checkout-Billing"
    }, {
      path: "Payment",
      component: _266bd4d4,
      name: "Checkout-Payment"
    }, {
      path: "Shipping",
      component: _96103764,
      name: "Checkout-Shipping"
    }, {
      path: "ThankYou",
      component: _c2f4a016,
      name: "Checkout-ThankYou"
    }]
  }, {
    path: "/grid-prototype",
    component: _0ce1f3f4,
    name: "grid-prototype"
  }, {
    path: "/Home",
    component: _572b1650,
    name: "Home"
  }, {
    path: "/MyAccount",
    component: _270a84b8,
    name: "MyAccount",
    children: [{
      path: "BillingDetails",
      component: _4984c6ce,
      name: "MyAccount-BillingDetails"
    }, {
      path: "MyNewsletter",
      component: _171c5068,
      name: "MyAccount-MyNewsletter"
    }, {
      path: "MyProfile",
      component: _5f1896b4,
      name: "MyAccount-MyProfile"
    }, {
      path: "OrderHistory",
      component: _60920da6,
      name: "MyAccount-OrderHistory"
    }, {
      path: "ShippingDetails",
      component: _d2210a86,
      name: "MyAccount-ShippingDetails"
    }]
  }, {
    path: "/Product",
    component: _3f3438f4,
    name: "Product"
  }, {
    path: "/redirecter",
    component: _b21840bc,
    name: "redirecter"
  }, {
    path: "/ResetPassword",
    component: _77c59a3e,
    name: "ResetPassword"
  }, {
    path: "/:slug*",
    component: _06696c3b,
    name: "custom"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
