import { Context } from '@nuxt/types';
import { Route } from 'vue-router';

export default (context: Context) => {
  context.app.router.beforeEach(
    async (to: Route, from: Route, next: () => void) => {
      if (from?.name === null) {
        return next();
      }

      // cancel routing on hash change only
      if (from?.path === to?.path) {
        if (to?.hash) {
          return;
        }
      }

      /*
            TODO use novulo.api.getURLInfo so determine if the link should be routed internally using next()
            or should be handled by the browser so the request is sent to the HAProxy
        */
      document.location.href = to.path;
    }
  );
};
