export function setStoreState({ app, i18n, $config }) {
  const domainConfig = $config.domainConfig;
  if (!domainConfig) {
    return;
  }
  const state = app.$vsf.$novulo.config.state;
  if (state.isset) {
    return;
  }

  let language = i18n.locale;
  let languageConfig = domainConfig.lang[language];
  let tweakwiseInstanceKey;
  if (!languageConfig) {
    language = Object.keys(domainConfig.lang)[0];
    app.i18n.setLocale(language);
    languageConfig = domainConfig.lang[language];

    // c9e36c86 is the default instance key for KE NL
    tweakwiseInstanceKey = 'c9e36c86';
  } else {
    tweakwiseInstanceKey = languageConfig.tweakwiseInstanceKey;
  }

  state.setWt(languageConfig.wt);
  state.setSalesChannel(domainConfig.salesChannel);
  state.setLanguage(language);
  state.setDomainConfig(domainConfig);
  state.setTweakwiseInstanceKey(tweakwiseInstanceKey);
  state.isset = true;
}
